.C-Layout__footer {
  width: 100vp !important;
  background-color: #efefef;
}

.ess_footer_div_left {
  flex-grow: 0;
  max-width: 25%;
  flex-basis: 25%;
}

#ess_footer .C-Anchor {
  color: #333333 !important;
  font-size: 13px;
  font-family: Roboto;
  font-weight: normal;
  line-height: 16px;
  text-decoration: none;
}

.ess_footer_link_padding {
  margin-left: 10px;
}

.ess_footer_pipe {
  margin: 0 10px;
}

.ess_footer_div_content {
  display: flex;
  border-top: 1px solid #d6d6d6; 
  margin-bottom: 10px;
  background-color: #fff;
}

.ess_div_content_right {
  text-align: right;
}

.ess_footer_div {
  text-align: left;
  background: transparent;
  box-shadow: none;
  flex-direction: row;
}
