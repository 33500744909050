/* START: Custom Styles for Footer to stick to the bottom of the page */

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: scroll;
}

.C-Layout {
  width: 100%;
  height: 100%;
}

footer {
  width: 100%;
  bottom: 0;
}

/* END: Custom Styles for Footer to stick to the bottom of the page */

/* Custom Style to Override the blue underline for Anchor elements in the Left Navigation links */
#ess_left_navigation .C-Anchor:focus,
.C-Anchor:hover {
  border-bottom: 0px !important;
}

.ess_error {
  width: 950px;
  color: red;
  padding-bottom: 25px;
}

/*This style is added to avoid the error stack being displayed on the UI. 
  Can be disabled in dev/stage environment for debugging the source of error on the screen*/
iframe {
  display: none;
}
